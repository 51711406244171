export const getImageSrc = (image) => {
  if ( image === 'string') {
    return image
  }

  if (image && image.childImageSharp) {
    return image.childImageSharp.fluid.src
  }

  return null
}

export const backgroundImageStyle = (image ) => {
  return {
    backgroundImage: `url(${image})`,
  }
}
