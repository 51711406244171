import React from 'react'

import { convertTitleToSlug } from '../../utils/convertTitleToSlug'
import { backgroundImageStyle } from '../../utils/image'
import Button from '../Button'
import GtmClick from '../GtmClick'
import Link from '../Link'
import styled from 'styled-components'
import './styles.css'

const Excerpt = styled.p`
`

const PostPreview = ({ post, className, thumbnailSize = 'small', tag}) => (
  <div className={className}>
    <div className={`post-card-wrapper post-card-wrapper-${thumbnailSize}`}>
      <GtmClick category="click" id={`gtm-resource-center-${convertTitleToSlug(post.title)}`}>
        <Link to={`/blog/${post.slug}/`} className="post-preview-body">
          <div
            style={backgroundImageStyle(`https:${post.heroImage.fluid.src}`)}
            className={`post-thumbnail post-thumbnail-${thumbnailSize}`}
          >
           {/* <Img fluid={post.heroImage.fluid} backgroundColor={'#eeeeee'} /> */}
          </div>
          <div className="post-preview-content">
            <div className="text-small">
              {!tag && <div className="text-small">
                <span className="tag-purple">{post.tags[0].title}</span>
              </div>}
              <h5 className="mt-0-5 post-preview-title">{post.title}</h5>
              <p className="text-small mb-0-5">{post.publishDate}</p>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: post.bodyFirst.childMarkdownRemark.excerpt,
                }}
              />
            </div>
            <div className="mt-1">
              <Button theme="text">
                Read more
              </Button>
            </div>
          </div>
        </Link>
      </GtmClick>
    </div>
  </div>
)



// const Card = ({
//   slug,
//   heroImage,
//   title,
//   publishDate,
//   body,
//   body: {
//     childMarkdownRemark: { timeToRead },
//   },
//   ...props
// }) => {
//   return (
//       <Link to={`/blog/${slug}/`}>
//         <h4>{title}</h4>
//         <h5>{publishDate}</h5>
//         <p>{timeToRead} min read</p>
//         <Excerpt
//           dangerouslySetInnerHTML={{
//             __html: body.childMarkdownRemark.excerpt,
//           }}
//         />
//       </Link>
//   )
// }



export default PostPreview
