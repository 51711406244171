export const convertTitleToSlug = (titleSlug) => {
  try {
    return titleSlug
      .replace(/\?/g, '')
      .replace(/\'/g, '')
      .replace(/([A-Z])([A-Z])/g, '$1-$2')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
  } catch (error) {
    return 'unknown'
  }
}
