import React from 'react'
import { graphql } from 'gatsby'
import NovaLayout from '../components/NovaLayout'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import PostPreview from '../components/PostPreview'

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const featuredPost = posts[0].node
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    <NovaLayout title="Blog | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <SEO />
      <div className="mb-8">
        {isFirstPage ? (
          <div className="layout-body">
            <div className="mt-2 grid-center-equalHeight">
              <div className="col-4_md-12 md-pl-8 md-pr-8 md-mb-4 xs-pl-1 xs-pr-2 md-text-center">
                <div className="mt-0-5">
                  <h2>Blog</h2>
                  <h4 className="text-large mt-1">Read our latest posts on how you can grow your businesses with inbound marketing and crush it.</h4>
                </div>
              </div>
              <div className="col-8_md-12">
                {/* <Card {...featuredPost} featured /> */}
                <PostPreview post={featuredPost} thumbnailSize="large" className="" />
              </div>
            </div>
            <div className="resource-main-section-posts grid-equalHeight mt-2">
              {posts.slice(1).map(({ node: post }) => (
                <PostPreview key={post.id} post={post} className="col-4_md-6_xs-12 mb-1" />
              ))}
            </div>
          </div>
        ) : (
          <div className="layout-body">
            <div className="resource-main-section-posts grid-equalHeight mt-2">
              {posts.map(({ node: post }) => (
                <PostPreview key={post.id} post={post} className="col-4_md-6_xs-12 mb-1" />
              ))}
            </div>
          </div>
        )}
      </div>
        
    
      <div className="layout-body hero-body">
        <div className="grid-equalHeight">
          
          <Pagination context={pageContext} />
        </div>
      </div>
    </NovaLayout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          tags {
            title
            id
            slug
          }
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          bodyFirst {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 130)
            }
          }
          bodySecond {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 130)
            }
          }
        }
      }
    }
  }
`

export default Index
